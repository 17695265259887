/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, forwardRef, useRef } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
//import { useSpeechContext } from '@speechly/react-client';
import { Icon } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import LinearProgress from '@mui/material/LinearProgress';
import {GetMic, GetExpMic, GetExplainerResponse, GetRandomOrganPart, GetQuestions, GetExplanation, GetSpeech, GetSFIActiveTopics, BuildQuizQuestions} from './api';
import Slide from '@mui/material/Slide';
import {Close } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//import useStayAwake from "use-stay-awake";
import { KeepAwake } from '@capacitor-community/keep-awake';
import { clear } from '@testing-library/user-event/dist/clear';


const cfDir = "https://us-central1-thackery-e30ca.cloudfunctions.net/"

const Quiz = (props) => {
	const [openBkdrop, setOpenBkdrop] = useState(false);
	const [bkdropText, setBkdropText] = useState("...Evaluating...");
 
	const [questions, setQuestions] = useState([]);
	const [expQuestions, setExpQuestions] = useState([]);
	const [questionsReq, setQuestionsReq] = useState(false);
	const [qSet, setQSet] = useState([]);
	const [currQ, setCurrQ] = useState(null);
	const [startQ, setStartQ] = useState(false);
	const [endQ, setEndQ] = useState(true);
	const [completeQ, setCompleteQ] = useState(false);
	const [answerCorrect, setAnswerCorrect] = useState(null);
	const [qNum, setQNum] = useState(-1);
	const [score, setScore] = useState(0);
	const [quizSize, setQuizSize] = useState(5);
	const [qTime, setQTime] = useState(90);
	const [showQScore, setShowQScore] = useState(false);
	const [showFinalScore, setShowFinalScore] = useState(false);
	const [nextQText, setNextQText] = useState("NEXT QUESTION");
	const [direction, setDirection] = useState("left");
	const [openModal, setOpenModal] = useState(false);
	const [popupText,setPopupText] = useState("");
	const [showProgress,setShowProgress] = useState("");
const [quizVisible,setQuizVisible] = useState(false);
const [quizSettingsVisible,setQuizSettingsVisible] = useState(true);
const [quizPanelVisible,setQuizPanelVisible] = useState(true);
const [hasListener,setHasListener] = useState(false);
const [handsFreeMode,setHandsFreeMode] = useState(false);
const [qRead,setQRead] = useState(false);
const [aRead,setARead] = useState(false);
const [currSpeech,setCurrSpeech] = useState("");
const [speechFinishedFn,setSpeechFinishedFn] = useState(null);
const [topicsList,setTopicsList] = useState([]);
const [selTopicsList,setSelTopicsList] = useState([]);
const [progress, setProgress] = React.useState(0);
const [timerEnabled, setTimerEnabled] = React.useState(false);
const [inpExplain, setInpExplain] = React.useState("");
const [inpPermExplain, setInpPermExplain] = React.useState("");
const [qExplain, setQExplain] = React.useState("");
const [partExplain, setPartExplain] = React.useState("");
const [organExplain, setOrganExplain] = React.useState("");
const [evalExplain, setEvalExplain] = React.useState("");
const [scoreExplain, setScoreExplain] = React.useState("");
const [explainerVisible, setExplainerVisible] = React.useState(false);
const [expResultVisible, setExpResultVisible] = React.useState(false);
const [expSubmitVisible, setExpSubmitVisible] = React.useState(true);
const [expNextVisible, setExpNextVisible] = React.useState(false);


//const quizSize = 5;
const basePoints = 10;
const answerVal = 2;
const [qScore, setQScore] = useState(basePoints);
const micRef = useRef()
const speechRef = useRef();
const topicsRef = useRef();
let init = false;
let timer 
//const device = useStayAwake();

const increment = qTime /100;
React.useEffect(() => {
	setProgress(0);
	setTimerEnabled(true);
 timer = setInterval(() => {
	if(timerEnabled === true){
	setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + increment));
	}
  }, 900);
  return () => {
	clearInterval(timer);
  };
}, [qNum]);

const keepAwake = async () => {
	await KeepAwake.keepAwake();
  };
  
  const allowSleep = async () => {
	await KeepAwake.allowSleep();
  };


//function to listen for keystrokes ctrl+e
const handleKeyDown = (e) => {
	//console.log("key down", e)
	if(e.ctrlKey && e.key === "b"){
		console.log("ctrl+e pressed", currQ)
		if(currQ.id && currQ.id.length > 0){
			window.location.href = "/editor?qid=" + currQ.id;
		}
		//window.location.href = "/editor?qid=-NdCuB1-4qx1llkatMNX";
	}
}

window.addEventListener("keydown", handleKeyDown);



const getExplainMessages = (inp, topic,organ) => [
	{
		"role": "system",
		"content": `You are an expert instructor on the subjects of human anatomy and medicine.`
		+ `Does the following accurately describe the principal purpose and functionality of : '${topic}' as part of the: ${organ}?`
		+ `User Description: ${inp}`
		+ `Provide reasons for your evaluation of the description.`
	+ ` Response should be in JSON format with a shape of {"role": "user", "topic":${topic},`
	+ `"evaluation":string value containing your evaluation of the description,`
	+ `"score":integer value from 0 to 10 based on the quality of the description,`
	+ `}`
	},
	{"role":"user",
"content":""
}
]

const getTreatmentMessages = (inp, q, topic) => [
	{
		"role": "system",
		"content": `You are an expert instructor on the subjects of human anatomy and medicine.`
		+ `Does the following accurately answer this question: '${q}'?`
		+ `\n\n User Answer: ${inp} \n\n`
		+ `Provide reasons for your evaluation of the description.`
	+ ` Response should be in JSON format with a shape of {"role": "user", "topic":${topic},`
	+ `"question":string value containing the treatment question,`
	+ `"evaluation":string value containing your evaluation of the answer,`
	+ `"score":integer value from 0 to 10 based on the quality of the answer,`
	+ `}`
	},
	{"role":"user",
"content":""
}

]


const getNextQ =  (newData) => {
		setShowQScore(false);
		setQScore(basePoints);
		setShowFinalScore(false);

setStartQ(true)
console.log("qnum",qNum)

if(qNum === quizSize-1 ){
	setNextQText("CHECK YOUR SCORE");
}
//setQNum(qNum + 1);
if(qNum < quizSize){
if(qNum >0 && startQ === true){
	console.log("hide slide")
	setStartQ(false);
}else{
	setQNum(qNum + 1);
}
// new data is set after slide transition
	
if(newData){
	
		setCurrQ(newData[qNum])
	}
resetAnswerStatus()
}else{
setShowFinalScore(true);
setQuizVisible(false);
setQNum(0);
setCurrQ(null);
setQuestionsReq(false);
}
	}

	const resetQuiz = ()=>{
		console.log("reset quiz")
		setNextQText("NEXT QUESTION");
		getQSet(quizSize, questions.length)
		setQuizVisible(false);
		setShowFinalScore(false);
		setExplainerVisible(false);
		setQuizSettingsVisible(true);
		//getNextQ();
	}

const resetAnswerStatus = ()=>{
// get answer elements
 let answers = document.querySelectorAll(".grid-answer");
 // iterate through answer elements
 for (var i = 0; i < answers.length; i++) {
	answers[i].classList.remove("answered-correct");
	answers[i].classList.remove("answered-incorrect");
  }

}

	const getQuestions = async() => {

	console.log("getting questions")
		let qs = await GetQuestions(undefined,topicsList, quizSize,topicsList, qTime);
	// qs = qs.response;
	
	console.log(qs)
		let arrQSet = Object.keys(qs).map((q,idx)=>{
		//	console.log(qSet[q])
			if(qs[q].id){
	return qs[q];
		}else{
			qs[q].id = q;
			return qs[q];
		}
		})
	
		// reverse the array order
		arrQSet = arrQSet.reverse();
		setQuestions(arrQSet);
		
	 if(currQ === null){
	 //	getNewCurrentQuestion();
	// getQSet(quizSize)
	 }

	}

const getRandomQ= (qLen)=>{
	let qRandom = Math.floor(Math.random() * qLen)
	console.log("random", qRandom)
	console.log(questions)
	let q = questions[qRandom];
	console.log(q)
	return q;

}

const getQSet=async (num, qLen)=>{
	let newSet = [];
	for(let i=0;i<num;i++){
let q =await getRandomQ(qLen);
console.log("question#", q)
//let q = getNextQ();
newSet.push(q);
	}
await Promise.all(newSet);
	console.log(newSet)
setQSet(newSet)
//setCurrQ(newSet[0]);

console.log(qSet);
console.log(quizVisible)
setQuizVisible(true)
getNextQ(newSet)
//getNewCurrentQuestion()
//getNextQ()
//setCurrQ(newSet[0])
//setStartQ(true)

}

	const getNewCurrentQuestion = async () => {
		console.log("getting current question")
// get 20 random questions from list with no duplicates

// get random question from list
		//let q = questions[Math.floor(Math.random() * questions.length)];
setCurrQ(qSet[0]);

console.log(qSet)
console.log(questions)
console.log(currQ)
		return qSet[0];
	}

	const getAnswerSet = (arrAnswers, corr) => {
	//	console.log(currQ)
	//	console.log(arrAnswers)
	if(arrAnswers && arrAnswers.length > 0){
		let jsxAnswers = arrAnswers.map((a, i)=>{
			if(a === corr){
	
				return (<div className="grid-answer correct" onClick={handleQClick} key={i}><div className="a-num">{i+1}.</div>{a}</div>)
			}else{
			return (<div className="grid-answer" onClick={handleQClick} key={i}><div className="a-num">{i+1}.</div>{a}</div>)
			}
		})
		return jsxAnswers;
	}
	}

	const handleQClick = (e) => {
console.log(e.target)
let sel ;

sel= e.target;

if(sel.classList.contains("correct")){
	//setTimerEnabled(false);
	//clearInterval(timer);
	// function to clear all intervals
	 for (var i = 1; i < 99999; i++) window.clearInterval(i);

	sel.classList.add("answered-correct");
	setTimeout(()=>{
		let explanation = sel.parentElement.parentElement.querySelector(".grid-explanation");
		explanation.classList.add("show");

		if(handsFreeMode === true){
			speechRef.current.speak('<speak>Correct! ' + currQ.explanation + '</speak>', ()=>handleSpeechFinished('explanation'));
		}
	}, 650);
	let penalty = 1 - progress/qTime * 2;
console.log("penalty", penalty);
console.log("qscore", qScore);
console.log("answer val", answerVal);
setQScore(parseInt(qScore * penalty));

	setScore(score + qScore);
	setShowQScore(true);
}else{
sel.classList.add("answered-incorrect");
let penalty = 1 - progress/qTime * 2;
console.log("penalty", penalty);
console.log("qscore", qScore);
console.log("answer val", answerVal);
setQScore(parseInt((qScore - answerVal) * penalty));
setTimeout(()=>{
if(handsFreeMode === true){
	speechRef.current.speak('<speak>Sorry, that is incorrect.</speak>', ()=>handleSpeechFinished('incorrect'));
	if(handsFreeMode === true){
		//micRef.current.resetMic();
		}
}
}, 150);
}

	}


	const handleSpeechFinished = (section)=>{
		
			console.log("speech finished", qRead, aRead)
			console.log("section", section)

			if(section === "question"){
				setQRead(true);
				speakAnswers();
			}
			if(section === "definition"){
				setPopupText('');
		setOpenModal(false);
			}
			if(section === "answers"){
				setARead(true);
				if(handsFreeMode === true){
					//micRef.current.resetMic();
					}
			}
			if(section === "explanation"){
				setTimeout(()=>{
					closeExp();
					getNextQ();	
				}, 600)
			}
			
		
	
	}

useEffect(()=>{
	console.log("sel topics", selTopicsList)
},[selTopicsList])

useEffect(()=>{
	console.log("qRead updated", qRead)
	console.log("aRead", aRead)
	if(qRead === true && aRead === false){
		console.log("q read", qRead)
		setQRead(true);
		// speakAnswers();
	
	}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[qRead,aRead])

	const handleSlideEntered = (e,isAppearing)=>{
		if(handsFreeMode === true){
			micRef.current.resetMic();
			}
		console.log(e)
		console.log("start", isAppearing)
setDirection("right")
		if(isAppearing === false){
		
		}

			let panel = document.querySelector(".grid-question");
			panel.addEventListener("mouseup", getSel);
			panel.addEventListener("touchend", getSel);
			setHasListener(true);	

			if(handsFreeMode === true){
setQRead(false);
setARead(false);
//GetSpeech('<speak>' + currQ.testQuestion + '</speak>',()=> handleSpeechFinished('question'));
//speak('<speak>' + currQ.testQuestion + '</speak>', ()=> handleSpeechFinished('question'));
//setCurrSpeech('<speak>' + currQ.testQuestion + '</speak>');
// setSpeechFinishedFn(()=> handleSpeechFinished('question'));
speechRef.current.speak('<speak>' + currQ.testQuestion + '</speak>',()=> handleSpeechFinished('question'));
			}
		
	}


	const speakAnswers = ()=>{	
let arrAns = currQ.multipleChoiceAnswers;
let labels = ["A", "B", "C", "D", "E"];
let strAns = arrAns.map((a,i)=>{
	let label = i + 1
let newStr = label + ". " + a +  '<break time="0.6s"/>';
return  newStr ;
});

let finalStr = strAns.join(" ");

	//GetSpeech('<speak>' + finalStr + '</speak>',()=> handleSpeechFinished('answers'));
	//setCurrSpeech('<speak>' + finalStr + '</speak>');
//setSpeechFinishedFn(()=> handleSpeechFinished('answers'));
speechRef.current.speak('<speak>' + finalStr + '</speak>',()=> handleSpeechFinished('answers'));

	}

	const handleSlideExited = (e,isHiding)=>{
		console.log("exit complete - hide slide")
		//setQNum(qNum + 1);
		//if(qSet[qNum]){
			setCurrQ(qSet[qNum]);
			setQNum(qNum + 1);
		//	}
			setTimeout(()=>{
				console.log("slide in next question")
				setDirection("left")
		setStartQ(true);
			},500)
		
		//}
	}
const	handleShowHint = ()=>{
		let hint = document.querySelector(".hint");
		hint.classList.add("show");
	}

const capitalizeFirstLetter = (string)=> {
	return string.charAt(0).toUpperCase() + string.slice(1);
	  }

	  const capForEachWord = (string)=> {
		let arr = string.split(" ");
		let newArr = arr.map((a)=>{
			return capitalizeFirstLetter(a);
		})
		return newArr.join(" ");
		  }


const handleShowExplanation = async()=>{
	//setInpExplain( "The " + {partExplain} + " is a part of the human body.");
	let mssgs = getExplainMessages(inpExplain, partExplain, organExplain);
	setOpenBkdrop(true);
	let resp = await GetExplainerResponse(mssgs);
	
	console.log("resp", resp)
	let detail = JSON.parse(resp.response.choices[0].message.content);
	setEvalExplain(detail.evaluation);
	setScoreExplain(detail.score);
setExpResultVisible(true);
setExpSubmitVisible(false);
setExpNextVisible(true);
setQNum(qNum + 1);
setScore(score + detail.score);
setOpenBkdrop(false);
	console.log("response", organExplain ,detail)
}


useEffect(()=>{
	console.log("currq", currQ)
	if(expQuestions.length > 0 ){
	nextExpQuestion();
	}
},[expQuestions])

const buildExplainerQuiz = async()=>{
let fullQuiz = [];

	for(let i=0;i<quizSize;i++){
		let parts =  GetRandomOrganPart();
		let q ={};
		q.organ = capForEachWord(parts.organ);
		q.part = capForEachWord(parts.part);
		q.question = `Explain the importance and function of the ${q.part} in the human body.`;
		console.log("explainer question", q)
		fullQuiz.push(q);
	}
	setCurrQ(fullQuiz[0]);
	setQNum(0);
	setExpQuestions(fullQuiz);

	//nextExpQuestion();
}

const nextExpQuestion = async(num)=>{
	console.log("currq", currQ)
	console.log("expq", expQuestions)
	console.log("qnum", qNum)
	setInpExplain("");
	if(qNum < expQuestions.length){
			setExpResultVisible(false);
			setEvalExplain("");
 setOrganExplain(expQuestions[qNum].organ);
 setPartExplain(expQuestions[qNum].part);
 setQExplain(expQuestions[qNum].question);
setExpSubmitVisible(true);
setExpNextVisible(false);
setExplainerVisible(true);	
setQuizSettingsVisible(false);
setQuizVisible(false);
	}else{

		setExplainerVisible(false);
		setShowFinalScore(true);
	}
}

const buildQuiz = async()=>{
	let arrIds = selTopicsList.map((t)=>{
		return t.qids;
	})
// randomize array
arrIds = arrIds.sort(() => Math.random() - 0.5);

let strIds = arrIds.join(",");
let q = await BuildQuizQuestions(strIds, quizSize, qTime);
console.log("filtered questions", q)
setQuestions(q);
setQuizSettingsVisible(false);
}

//useEffect(()=>{
	const getData= ()=>{
		
		console.log("get data to begin quiz")
	if(questions.length === 0 && questionsReq === false){
		console.log("get questions via api")
		setQuestionsReq(true);
		if(questions.length === 0){
  getQuestions();
		}
	}else{
		console.log("questions already populated")
		setQuizVisible(true)
		
	}
	
	}	
	//getData()
// eslint-disable-next-line react-hooks/exhaustive-deps
//}, []);
useEffect(()=>{
	console.log("init");
	if(topicsList.length === 0 && init === false){
	getTopList();
	}
	init = true
}, []);

const getTopList =async ()=>{

	let topics = await GetSFIActiveTopics();
	
	// sort array
	topics = topics.sort((a,b)=>{
		if(a.text < b.text){
			return -1;
		}
		if(a.text > b.text){
			return 1;
		}
		return 0;
	})
	console.log("sorted topics",topics)
	setTopicsList(topics);
}

useEffect(()=>{
	console.log("questions populated - checking currQ")
	setQuizVisible(true)
	console.log(questions)
	console.log(questions.length)
	//if(currQ === null){
		//if(topicsList.length === 0){
		getQSet(quizSize, questions.length)
		//}
 //getNewCurrentQuestion();
	//}	
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [questions]);

	const getSel = async() => {
		if(quizVisible === true){
		console.log("get selection")
	let sel = window.getSelection();
	console.log(sel)
	if(sel.focusNode !== null){
	let node = sel.focusNode.nodeValue;
	let start = sel.baseOffset;
	let end = sel.extentOffset;
	console.log(start, end)
	let term = node.substring(start, end);
	console.log(term);
	if(term.length > 0){
	setShowProgress(true);
		let exp = await GetExplanation(term);
		console.log(exp)
		let eText = exp.response.explanation;

		setPopupText(eText);
		setOpenModal(true);
	setShowProgress(false);
	}

	}
		}	
	//let rng = window.getSelection().getRangeAt(0);	
	}
const closeExp = (e) => {
	
	let exp = document.querySelector(".grid-explanation")//e.target.parentElement.parentElement;
	console.log("close exp", exp)
	exp.classList.remove("show");
}
	
const handleCloseModal = ()=>{
	setOpenModal(false);
	  }

 
const closeHint = (e) => {
	
	let hint = e.target.parentElement.parentElement;
	console.log("close hint", hint)
	hint.classList.remove("show");
}

const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element){
  mouseClickEvents.forEach(mouseEventType =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
      })
    )
  );
}

const updateExpReply = async(txt, replyType)=>{
console.log("final reply", txt)
	setInpExplain(inpPermExplain + " " + txt);

}

const updateReply = async(txt, replyType)=>{
	console.log("final reply", txt)
	console.log("reply type", replyType)

	if(replyType === "answer" && qRead === true && aRead === true){
		const nums = ["one", "two", "three", "four", "five",];
		 
		let aNum = nums.indexOf(txt.command);
		if(aNum > -1){
		console.log("aNum", aNum)
		let selAnswer = document.querySelectorAll(".grid-answer")[aNum];
		simulateMouseClick(selAnswer);
		}else{
			speechRef.current.speak('<speak>Sorry, I didn\'t get that. Please try again.</speak>', ()=>handleSpeechFinished('error'));
		}
	}

	if(replyType === "execute"){
		
if(txt.includes("next") || txt.includes("next question")){
	console.log("next question", qNum, quizSize)
	if(qNum === 0 ){
		resetQuiz();
	}else{
	getNextQ();
	}
}
	
if(txt.includes("pause")){
speechRef.current.pauseSpeech();
}
	
if(txt.includes("play")){
speechRef.current.playSpeech();
}

	if(txt.includes("repeat the question")){
		speechRef.current.speak('<speak>' + currQ.testQuestion + '</speak>',()=> handleSpeechFinished('question'));
	}

	if(txt.includes("repeat the answers")){
		speakAnswers();
	}
	
}

if(replyType === "explain"){
console.log("explain", txt)
setShowProgress(true);
		let exp = await GetExplanation(txt);
		console.log(exp)
		let eText = exp.response.explanation;

		setPopupText(eText);
		speechRef.current.speak('<speak>' + eText + '</speak>', ()=>handleSpeechFinished('definition'));
		setOpenModal(true);
	setShowProgress(false);
}
}

const addToSelTopics = (e, value)=>{
// let sel = [...selTopicsList];
// if(value){
// sel.push(value);
setSelTopicsList(value);

}



	return (
		<>
<div className={quizSettingsVisible=== true ?  "q-params-panel": "hidden"} >

<div className="quiz-heading">Setup Quiz</div>
<Stack direction="row" spacing={4} className='params-container' 
 justifyContent="center"
useFlexGap flexWrap="wrap">
<div>
<Autocomplete
//ref={topicsRef}
  disablePortal
  id="autoTopics"
  multiple 
  className="auto-topics"
 onChange={addToSelTopics}
 options={topicsList.sort((a, b) => -b.text.trim().localeCompare(a.text.trim()))}
 getOptionLabel={(option) => option.text.trim()}
 // options={topicsList}
  sx={{ width: '100%' }}
  renderInput={(params) => <TextField {...params} label="Topics" />}
/>   
</div>
<div>
<TextField className='q-num' 
label="Number of Questions"
value={quizSize}
onChange={(e)=>{setQuizSize(e.target.value)}}

></TextField>
</div>
<div>
<TextField className='q-time' 
label="Time Per Question(sec)"
value={qTime}
onChange={(e)=>{setQTime(e.target.value)}}

></TextField>
</div>
</Stack>

<div className="quiz-btns-start"> 
 <Button 
	 variant="contained"
	 onClick={()=>{buildQuiz()}}
	 >Build Quiz</Button>
 <Button 
	 variant="contained"
	 onClick={()=>{buildExplainerQuiz()}}
	 >Build Explainer Quiz</Button>
	 
	 </div>
		</div>





<div className={quizVisible=== true && explainerVisible=== false ? "" : "hidden"}>
			<div className="quiz-heading">Quiz Demo</div>
			<div className="settings-wrapper">
				
				<div>
				<GetMic ref={micRef} onStartListening={()=>{
					//	device.preventSleeping();	
					keepAwake();
					setHandsFreeMode(true)}
					} onStopListening={()=>{
						allowSleep();
						setHandsFreeMode(false);
						}} onFinalChange={updateReply}  />
				
				</div>
			</div>
			{currQ ? 
			<>
			<div className="qNum">
				{qNum } of {quizSize}
				<div className="q-prog">
				<CircularProgress variant="determinate" value={progress + 10} />
				<div className="timer-num">{parseInt(qTime - progress) >= 0 ? parseInt(qTime - progress):0}</div>
				</div>
				
			</div>
			
		  <Slide direction={direction} in={startQ} timeout={800} onEntered={handleSlideEntered}   onExited={handleSlideExited} mountOnEnter unmountOnExit>
			
		<div className="quiz-panel quiz">
			
		   <div className="grid-question">{currQ.testQuestion}</div>
	 
	  
	   <div className="grid-details ">
	   <div className="grid-answers">{getAnswerSet(currQ.multipleChoiceAnswers, currQ.correctAnswer)}</div>
	   
	   <div className="grid-explanation" onClick={closeExp}>
	   <div className={showQScore === true ? "qScore": "qScore hidden"}>
				+{qScore}
			</div>
		<div className="close-icon" onClick={closeExp} ><Close></Close></div>Correct! {currQ.explanation}</div>
	   <div className="hint" onClick={closeHint}><div className="close-icon" ><Close></Close></div>Here's a hint: {currQ.hint}</div>
 
	   <div className="quiz-btns">
   
		   <Button 
		   variant="contained"
		   onClick={()=>{handleShowHint()}}
		   >Give Me a Hint</Button>
		   <Button 
		   variant="contained"
		   onClick={()=>{getNextQ()}}
		   >{nextQText}</Button>
	   </div>
	   </div>	
	   </div>
	   </Slide>
	   <GetSpeech ref={speechRef} txt={currSpeech} onFinished={speechFinishedFn} />
	   <Modal 
	 open={openModal}
	 >
		<Box className="popup-box">
		<Icon className="close-btn" onClick={handleCloseModal}><HighlightOffIcon className="icon" /></Icon>
	<div>{popupText}</div>
	</Box>
		</Modal>

		
		<Icon className={showProgress=== true ? "progress" : "progress hidden"} ><CircularProgress className="icon" /></Icon>
		
	   </>
	 : <div className="quiz-btns-start">  <Button 
	 variant="contained"
	 onClick={()=>{getData()}}
	 >Start Quiz</Button></div>
	 	}
		</div>

		<div className={explainerVisible=== true ? "explainer-panel" : "hidden explainer-panel"}>
<div className="q-header">Explain It!</div>
<div className="sub-head">Parts of the {organExplain}</div>
<div className="exp-question">{qExplain}</div>
<div className="exp-inputs">
	<GetExpMic ref={micRef} onStartListening={()=>{
	console.log("starting listening")
					//	device.preventSleeping();	
					//keepAwake();
					//setHandsFreeMode(true)
			}
					} onStopListening={(trans)=>{
						console.log("stopping listening")
						console.log(trans);
						//updateExpReply(trans)
						//allowSleep();
						//setHandsFreeMode(false);
						setInpPermExplain(inpPermExplain + " " + trans)
						}} 
						onFinalChange={updateExpReply}  
						/>
				
				
<TextField className='inp-explain' 
multiline
value={inpExplain}
onChange={(e)=>{setInpExplain(e.target.value)}}
></TextField>
</div>

<div className={expResultVisible === true ? "exp-result" : "hidden"}>
	<div className="exp-score">Score: {scoreExplain}</div>
	<div className="exp-explain">Explanation: {evalExplain}</div>
</div>
<div>
<Button 
className={expSubmitVisible === true ? "" : "hidden"}
		   variant="contained"
		   onClick={()=>{handleShowExplanation()}}
		   >Submit</Button>
<Button 
className={expSubmitVisible === true ? "" : "hidden"}
		   variant="contained"
		   onClick={()=>{
			setInpPermExplain("");
			setInpExplain("")}}
		   >Clear</Button>
<Button 
className={expNextVisible === true ? "" : "hidden"}
		   variant="contained"
		   onClick={()=>{
			//setCurrQ(expQuestions[qNum]);
			//setQNum(qNum + 1);
			nextExpQuestion();

		   }}
		   >Next</Button>
</div>
		</div>
		<div className={showFinalScore === true ? "final-score" : "final-score hidden"}>
			<div className="score">
Congratulations you scored {score} out of {quizSize * basePoints}!
		</div>
		<div>
		<Button 
		   variant="contained"
		   onClick={()=>{resetQuiz()}}
		   >Play Again</Button>	
		<Button 
		className="hidden"
		   variant="contained"
		   onClick={()=>{props.hideQuiz()}}
		   >RETURN TO EDITOR</Button>	

		</div>
		</div>

		<Backdrop
	sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
	open={openBkdrop}
	
  >
  <CircularProgress color="inherit" />
  <div className='bkdrop-text'>{bkdropText}</div>
  </Backdrop>
	   </> 
	)

	
	

}

export default Quiz;