/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, forwardRef, useRef } from 'react';



const Portal = () => {

	useEffect(() => {
		let entries = document.querySelectorAll(".entry");
		entries.forEach ((entry)=>{
			console.log(entry)
			entry.addEventListener("mouseout",()=>{
				console.log("mouseout")
				entry.classList.add("desat")
			})

			if(entry.classList.contains("sfi")){
				entry.addEventListener("click",()=>{
					console.log("click")
					window.location.href = "/quiz"
				})
			}
			if(entry.classList.contains("medprof")){
				entry.addEventListener("click",()=>{
					console.log("click")
					window.location.href = "/professionals"
				})
			}

			if(entry.classList.contains("patient")){
				entry.addEventListener("click",()=>{
					console.log("click")
					window.location.href = "https://medibot.studyforit.ai"
				})
			}
		})
		// entry.addeventlistener("mouseout",()=>{
		// 	console.log("mouseout")
		// 	entry.classList.add("desat")
		// })
	}, [])
	return (
		<div className="portal">
			<h1>MedGateway.ai</h1>
			
			<div>AI medical resources for students, medical professionals, and patients</div>
			<div>your all-in-one platform designed to revolutionize medical education, practice, and patient care. whether you're a dedicated student, an experienced doctor, or a curious patient, we've got you covered.</div>
		
		<div className="options">
<div className="entry sfi">
<div className="img-wrapper">
				<img src="./images/sfi-student.png" alt="SFI" />
			</div>
	<div className="heading">Students</div>
	<div className="descrip">Are you a medical student aspiring to excel in your studies and exams? Access a wealth of educational resources, including ai-generated practice questions tailored to your study materials. Prepare for success with confidence.</div>
</div>
<div className="entry medprof">
	
<div className="img-wrapper">
				<img src="./images/sfi-med-prof.png" alt="SFI" />
			</div>
	<div className="heading">Medical Professionals</div>
	<div className="descrip">Are you a medical professional looking for new tools and resources to help you provide the best possible care? Look no further! Our tools can help streamline and enhance patient intake and diagnostic research. </div>
</div>
<div className="entry patient">
	
<div className="img-wrapper">
				<img src="./images/sfi-patient.png" alt="SFI" />
			</div>
	<div className="heading">Patients</div>
	<div className="descrip">Patients, your well-being matters to us. Discover valuable insights into your health conditions, treatment options, and ways to engage in your healthcare journey. Access trusted information and resources to make informed decisions.</div>
</div>
		</div>
		</div>
	)
}

export {Portal}