/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, forwardRef, useRef } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
//import { useSpeechContext } from '@speechly/react-client';
import { Icon } from '@mui/material';
import Slide from '@mui/material/Slide';
import {Close } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//import useStayAwake from "use-stay-awake";
import { clear } from '@testing-library/user-event/dist/clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {examRoom} from '../images/exam_room.jpg';

const cfDir = "https://us-central1-thackery-e30ca.cloudfunctions.net/"

const DrUpload = (props) => {
	const [openBkdrop, setOpenBkdrop] = useState(false);
	const [showForm, setShowForm] = useState('hidden');
	const [showUpload, setShowUpload] = useState(true);
	const [bkdropText, setBkdropText] = useState("...Evaluating...");
 
	const [questions, setQuestions] = useState([]);
	const [dResults, setDResults] = useState(null);
	const [chiefComplaint, setChiefComplaint] = useState('');
	const [onset, setOnset] = useState('');
	const [location, setLocation] = useState('');
	const [duration, setDuration] = useState('');
	const [characteristic, setCharacteristic] = useState('');
	const [factors, setFactors] = useState('');
	const [radiation, setRadiation] = useState('');
	const [timing, setTiming] = useState('');
	const [severity, setSeverity] = useState('');


const uploadTranscript = async (event) => {
	const file = event.target.files[0];
	if(file){
  const formData = new FormData();
  formData.append('file', file);
setOpenBkdrop(true);
 let rawResp = await fetch(cfDir+'uploadTranscript', {
	method: 'POST',
	body: formData
  })
  let resp = await rawResp.json();
  let diagData = resp.response.choices[0].message.content;
  console.log(resp.response.choices[0].message.content);
 let data = parseResults(diagData);
 setDResults(data);
 setOpenBkdrop(false);
	}
}

const reset = () => {
setDResults(null);
setChiefComplaint('');
setOnset('');
setLocation('');
setDuration('');
setCharacteristic('');
setFactors('');
setRadiation('');
setTiming('');
setSeverity('');


}

const handleSoapForm = async () => {

	let jData = {data:{
		"chief_complaint": chiefComplaint,
		"onset": onset,
		"location": location,
		"duration": duration,
		"characteristic": characteristic,
		"factors": factors,
		"radiation": radiation,
		"timing": timing,
		"severity": severity
	}}
	let soapData = JSON.stringify(jData);

	let rawResp = await fetch(cfDir+'uploadTranscript', {
		method: 'POST',
		body: soapData
	  })
	  let resp = await rawResp.json();
	  let diagData = resp.response.choices[0].message.content;
	  console.log(resp.response.choices[0].message.content);
	 let strData = parseResults(diagData);
	 setDResults(strData);
	 setOpenBkdrop(false);
}

const returnToPortal = () => {
	window.location.href = "/";
}

const parseResults = (strRes) => {
if(strRes){

	let results = JSON.parse(strRes);
console.log("links",results.links)
//let hyperlinks = "links";
console.log("res",results)
	 let hyperlinks = results.links.map((link)=>{
	 	return <div><a href={link } target="_blank" rel="noreferrer">{link}</a> </div>
	 })

	return <div className='results'>
		<div className="subheading">Results</div>
	<div className="result"><span>Diagnosis:</span> {results.diagnosis}</div>
	<div className="result"><span>Reasoning:</span> {results.reasons}</div>
	<div className="result"><span>Recommendations:</span> {results.treatment}</div>
	<div className="result"><span>links:</span> {hyperlinks}</div>
	
	
	</div>
	}else{
		return <div className="result">No results available</div>
	}
	


}

	return (
		<>
<div className="upload-wrapper">
	<div className="bkgrd-filter"></div>
	<div className="content-wrapper">
		<div className={`upload-form ${showUpload === true ? "" : "hidden"}`}>
<div className="quiz-heading">Upload Transcript</div>

<div>
	<label htmlFor="contained-button-file" className="label-upload">
<IconButton  variant="contained" color="primary" component="span">
<CloudUploadIcon />
    </IconButton ><span className="btn-label">Upload</span>
	</label>
	<input
        accept="text/*"
        className="file-input"
        id="contained-button-file"
        type="file"
		onChange={uploadTranscript}
		hidden
      />
</div>
<div className="link">(<a target="_self" href='https://medibot.studyforit.ai'>From Medibot</a>)</div>
</div>
<div>

<div className={`manual-form ${showUpload === true ?  "hidden" : ""}`}>
<div className="quiz-heading">Enter Symptoms</div>

	<div className="f-fields">
		<TextField
		  id="inp_chief_complaint"
		  label="Chief Complaint"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={chiefComplaint}
		  onChange={(e) => {setChiefComplaint(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>
		<TextField
		  id="inp_onset"
		  label="Onset"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={onset}
		  onChange={(e) => {setOnset(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>
		<TextField
		  id="inp_Location"
		  label="Location"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={location}
		  onChange={(e) => {setLocation(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>
		<TextField
		  id="inp_Duration"
		  label="Duration"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={duration}
		  onChange={(e) => {setDuration(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>
		<TextField
		  id="inp_Characteristic"
		  label="Characteristic"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={characteristic}
		  onChange={(e) => {setCharacteristic(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>

		<TextField
		  id="inp_Factors"
		  label="Factors"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={factors}
		  onChange={(e) => {setFactors(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>

		<TextField
		  id="inp_Radiation"
		  label="Radiation"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={radiation}
		  onChange={(e) => {setRadiation(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>

		<TextField
		  id="inp_Timing"
		  label="Timing"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={timing}
		  onChange={(e) => {setTiming(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>

		<TextField
		  id="inp_Severity"
		  label="Severity"
		  multiline
		  rows={1}
		  defaultValue=""
		  value={severity}
		  onChange={(e) => {setSeverity(e.target.value)}}
		  variant="outlined"
		  className="f-field"
		/>
	

	</div>
	<Button variant="contained" onClick={handleSoapForm} >Submit</Button>
</div>
<div>
	{dResults ? dResults : <div className="results">No current data</div>}
</div>
<div  className="btn-show-form">
<Button variant="contained"className={`${showUpload === true ? "" : "hidden"}`}  onClick={()=>{setShowUpload(!showUpload)}}>Or enter symptoms manually</Button>
<Button variant="contained" className={`${showUpload === false ? "" : "hidden"}`} onClick={()=>{setShowUpload(!showUpload)}}>Upload a transcript from Medibot</Button>
<Button variant="contained" onClick={()=>{reset()}}>Reset</Button>
<Button variant="contained" onClick={()=>{returnToPortal()}}>Return To MedGateway</Button>
</div>
</div>

</div>
</div>
<div>

		<Backdrop
	sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
	open={openBkdrop}
	
  >
  <CircularProgress color="inherit" />
  <div className='bkdrop-text'>{bkdropText}</div>
  </Backdrop>
  </div>
  
	   </> 
	)

	
	

}

export default DrUpload;