import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Configuration, OpenAIApi } from "openai";
//import { useSpeechContext } from '@speechly/react-client';
import { Icon } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import LinearProgress from '@mui/material/LinearProgress';

import Switch from '@mui/material/Switch';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Bottleneck from "bottleneck";

const cfDir = "https://us-central1-thackery-e30ca.cloudfunctions.net/"
const arrExplainParts =["Lymph nodes", "Thyroid", "Adrenals", "Pituitary gland", "Ovaries", "Testes", "Nerves", "Spinal cord", "Gallbladder", "Spleen", "Appendix", "Bronchi", "Trachea", "Diaphragm", "Esophagus", "Colon", "Rectum", "Ureters", "Urethra", "Adipose tissue", "Blood vessels", "Capillaries", "Pharynx", "Larynx", "Sinuses", "Tonsils", "Adenoids", "Parathyroid glands", "Pineal gland", "Pancreatic duct", "Nasal cavity", "Oral cavity", "Salivary glands", "Vocal cords", "Eustachian tubes", "Middle ear", "Semicircular canals", "Cochlea", "Cornea", "Sclera", "Retina", "Lens", "Optic nerve", "Conjunctiva", "Iris", "Pupil", "Eyelids", "Tear ducts", "Tongue", "Teeth", "Gums", "Mandible", "Maxilla", "Palate", "Olfactory bulbs", "Cervix", "Prostate", "Seminal vesicles", "Vas deferens", "Fallopian tubes", "Endometrium", "Myometrium", "Pericardium", "Pleura", "Peritoneum", "Synovial membranes", "Cartilage", "Tendons", "Ligaments", "Fascia", "Bursae", "Menisci"]

const arrOrganParts ={
	"Lymph nodes": ["Cortex", "Medulla", "Lymphoid follicles"],
	"Thyroid": ["Lobes", "Isthmus", "Follicular cells", "Parafollicular cells"],
	"Adrenals": ["Cortex", "Medulla"],
	"Pituitary gland": ["Anterior lobe", "Posterior lobe", "Intermediate lobe"],
	"Ovaries": ["Germinal epithelium", "Cortex", "Medulla"],
	"Testes": ["Seminiferous tubules", "Leydig cells", "Sertoli cells"],
	"Nerves": ["Axons", "Dendrites", "Myelin sheath", "Node of Ranvier"],
	"Spinal cord": ["White matter", "Gray matter", "Central canal", "Dorsal horns", "Ventral horns"],
	"Gallbladder": ["Fundus", "Body", "Neck", "Cystic duct"],
	"Spleen": ["White pulp", "Red pulp", "Capsule", "Trabeculae"],
	"Appendix": ["Lumen", "Mucosa", "Submucosa", "Muscularis"],
	"Bronchi": ["Bronchial tubes", "Bronchial walls", "Cartilage rings"],
	"Trachea": ["Tracheal rings", "Mucosa", "Submucosa"],
	"Diaphragm": ["Central tendon", "Costal muscle", "Crural muscle"],
	"Esophagus": ["Mucosa", "Submucosa", "Muscularis externa", "Adventitia"],
	"Colon": ["Ascending colon", "Transverse colon", "Descending colon", "Sigmoid colon"],
	"Rectum": ["Rectal valves", "Muscular layer", "Mucosa"],
	"Ureters": ["Lumen", "Mucosa", "Muscular layer", "Adventitia"],
	"Urethra": ["Urethral sphincter", "Urethral gland", "Urethral lumen"],
	"Adipose tissue": ["Adipocytes", "Stromal vascular fraction"],
	"Blood vessels": ["Tunica intima", "Tunica media", "Tunica adventitia"],
	"Capillaries": ["Endothelial cells", "Basement membrane", "Pericytes"],
	"Pharynx": ["Nasopharynx", "Oropharynx", "Laryngopharynx"],
	"Larynx": ["Vocal folds", "Thyroid cartilage", "Epiglottis"],
	"Sinuses": ["Frontal sinus", "Maxillary sinus", "Sphenoid sinus", "Ethmoid sinus"],
	"Tonsils": ["Palatine tonsils", "Lingual tonsils", "Pharyngeal tonsil"],
	"Adenoids": ["Lymphatic tissue"],
	"Parathyroid glands": ["Chief cells", "Oxyphil cells"],
	"Pineal gland": ["Pinealocytes", "Interstitial cells"],
	"Pancreatic duct": ["Duct cells", "Acini cells"],
	"Nasal cavity": ["Nasal septum", "Conchae", "Meatuses"],
	"Oral cavity": ["Oral vestibule", "Oral cavity proper", "Palate", "Floor of the mouth"],
	"Salivary glands": ["Parotid gland", "Submandibular gland", "Sublingual gland"],
	"Vocal cords": ["Thyroarytenoid muscle", "Vocal ligament", "Conus elasticus"],
	"Eustachian tubes": ["Pharyngeal opening", "Cartilaginous portion", "Osseous portion"],
	"Middle ear": ["Ossicles", "Tympanic cavity", "Mastoid antrum"],
	"Semicircular canals": ["Anterior canal", "Posterior canal", "Lateral canal"],
	"Cochlea": ["Scala vestibuli", "Scala media", "Scala tympani"],
	"Cornea": ["Epithelium", "Stroma", "Endothelium"],
	"Sclera": ["Episclera", "Stroma", "Lamina fusca"],
	"Retina": ["Photoreceptor cells", "Bipolar cells", "Ganglion cells"],
	"Lens": ["Capsule", "Subcapsular epithelium", "Lens fibers"],
	"Optic nerve": ["Optic disc", "Optic cup", "Retinal ganglion cells"],
	"Conjunctiva": ["Palpebral conjunctiva", "Bulbar conjunctiva", "Fornix conjunctiva"],
	"Iris": ["Stroma", "Sphincter pupillae muscle", "Dilator pupillae muscle"],
	"Pupil": ["Opening in the iris"],
	"Eyelids": ["Tarsal plates", "Orbicularis oculi muscle", "Levator palpebrae superioris muscle"],
	"Tear ducts": ["Lacrimal glands", "Lacrimal canaliculi", "Nasolacrimal duct"],
	"Tongue": ["Lingual papillae", "Taste buds", "Lingual tonsils"],
	"Teeth": ["Crown", "Root", "Pulp cavity"],
	"Gums": ["Gingiva"],
	"Mandible": ["Body", "Ramus", "Alveolar process"],
	"Maxilla": ["Body", "Palatine process", "Alveolar process"],
	"Palate": ["Hard palate", "Soft palate"],
	"Olfactory bulbs": ["Mitral cells", "Glomeruli", "Olfactory nerve fibers"],
	"Cervix": ["Endocervix", "Exocervix", "Cervical canal"],
	"Prostate": ["Prostate glands", "Prostate stroma"],
	"Seminal vesicles": ["Glandular tissue", "Ejaculatory duct"],
	"Vas deferens": ["Muscular layer", "Lumen", "Spermatic cord"],
	"Fallopian tubes": ["Infundibulum", "Ampulla", "Isthmus", "Fimbriae"],
	"Endometrium": ["Basal layer", "Functional layer"],
	"Myometrium": ["Inner layer", "Middle layer", "Outer layer"],
	"Pericardium": ["Fibrous pericardium", "Serous pericardium"],
	"Pleura": ["Visceral pleura", "Parietal pleura"],
	"Peritoneum": ["Visceral peritoneum", "Parietal peritoneum"],
	"Synovial membranes": ["Synovial cells", "Synovial fluid"],
	"Cartilage": ["Chondrocytes", "Extracellular matrix"],
	"Tendons": ["Collagen fibers", "Tenocytes"],
	"Ligaments": ["Fibroblasts", "Collagen fibers"],
	"Fascia": ["Elastic fibers", "Collagen fibers"],
	"Bursae": ["Synovial cells", "Synovial fluid"],
	"Menisci": ["Fibrocartilage", "Collagen fibers"]
  }

const GetRandomOrganPart = () => {

// get random organ part from arrOrganParts also get parent part
//let organ = 
let randParentOrgan= arrExplainParts[Math.floor(Math.random() * arrExplainParts.length)];
let randPart = arrOrganParts[randParentOrgan][Math.floor(Math.random() * arrOrganParts[randParentOrgan].length)];


//let arrParts = Object.keys(arrOrganParts);
//let randPart = arrParts[Math.floor(Math.random() * arrParts.length)];
return {"organ":randParentOrgan,"part": randPart};

}

  const GetExplainerResponse = async (mssgs) => {
	console.log("mssgs", mssgs);
let response = await fetch(cfDir + `getGenericResp?mssgs=` + JSON.stringify(mssgs));
let resp = await response.json();
console.log(resp)
return resp;

  }
const GenerateQuestions = async (topics, num, model) => {
	//let questions;
	//	let strTopics = topics.join(",");
		//const response = await fetch(cfDir + `createNBMEQuestions?topics=` + strTopics + "&num=" + num + "&model=" + model);
// 	const respTxt = await response.json();
//  questions = respTxt //JSON.parse(respTxt.response.text);
// 	console.log(questions)

//const arrexplainParts =["Integumentary system", "Skeletal system", "Muscular system", "Nervous system", "Endocrine system", "Cardiovascular system", "Lymphatic system", "Respiratory system", "Digestive system", "Urinary system", "Reproductive system", "Brain", "Heart", "Lungs", "Liver", "Pancreas", "Kidneys", "Stomach", "Intestines", "Bladder", "Skin", "Bones", "Muscles", "Arteries", "Veins", "Lymph nodes", "Thyroid", "Adrenals", "Pituitary gland", "Ovaries", "Testes", "Nerves", "Spinal cord", "Gallbladder", "Spleen", "Appendix", "Bronchi", "Trachea", "Diaphragm", "Esophagus", "Colon", "Rectum", "Ureters", "Urethra", "Adipose tissue", "Blood vessels", "Capillaries", "Pharynx", "Larynx", "Sinuses", "Tonsils", "Adenoids", "Parathyroid glands", "Pineal gland", "Pancreatic duct", "Nasal cavity", "Oral cavity", "Salivary glands", "Vocal cords", "Eustachian tubes", "Middle ear", "Semicircular canals", "Cochlea", "Cornea", "Sclera", "Retina", "Lens", "Optic nerve", "Conjunctiva", "Iris", "Pupil", "Eyelids", "Tear ducts", "Tongue", "Teeth", "Gums", "Mandible", "Maxilla", "Palate", "Olfactory bulbs", "Cervix", "Prostate", "Seminal vesicles", "Vas deferens", "Fallopian tubes", "Endometrium", "Myometrium", "Pericardium", "Pleura", "Peritoneum", "Synovial membranes", "Cartilage", "Tendons", "Ligaments", "Fascia", "Bursae", "Menisci"]

  


// Medical conditions with symptoms that can be misleading and suggest one cause when the actual cause is different are known as "clinical masqueraders." These conditions can present diagnostic challenges. Here are a few examples and their diagnostic approaches:

// Myocardial Infarction vs. Gastroesophageal Reflux Disease (GERD):

// Symptoms: Chest pain can be a symptom of a heart attack but can also be caused by GERD.
// Diagnostic approach: Distinguishing between these can involve an ECG to check for signs of myocardial infarction, blood tests for cardiac biomarkers (troponins), and a stress test. For GERD, an upper endoscopy, pH monitoring of the esophagus, or a trial of proton pump inhibitors might be used.
// Asthma vs. Vocal Cord Dysfunction (VCD):

// Symptoms: Both can cause difficulty breathing, wheezing, and cough.
// Diagnostic approach: Asthma is typically diagnosed with pulmonary function tests showing reversible airway obstruction, while VCD is diagnosed using laryngoscopy to observe the vocal cords' function during symptoms.
// Multiple Sclerosis (MS) vs. Vitamin B12 Deficiency:

// Symptoms: Both can cause neurological symptoms like numbness, weakness, and gait disturbances.
// Diagnostic approach: MS can be diagnosed with MRI scans showing central nervous system lesions and possible lumbar puncture, while vitamin B12 deficiency is diagnosed with blood tests for B12 levels and methylmalonic acid.
// Depression vs. Hypothyroidism:

// Symptoms: Fatigue, weight gain, and depression can be signs of both.
// Diagnostic approach: A psychological evaluation can help diagnose depression, while a blood test checking TSH (thyroid-stimulating hormone) levels can diagnose hypothyroidism.
// Diabetic Ketoacidosis (DKA) vs. Sepsis:

// Symptoms: Both can present with high fever, dehydration, rapid breathing, and confusion.
// Diagnostic approach: DKA is diagnosed with blood tests showing high blood sugar, ketones, and acidosis. Sepsis is diagnosed by identifying the source of infection, elevated white blood cells, and other markers of infection.


// 	return questions;
let newQList;
let facts;
	let limResult =	limiter.schedule(async() => {
console.log("generate q from topics", topics)
		newQList =	topics.map(async(topic)=>{
			   const response = await fetch(cfDir + `createNBMEQuestions?topic=` + topic + "&num=" + num + "&model=" + model );
		   const respTxt = await response.json();
		facts = respTxt //JSON.parse(respTxt.response.text);
		   console.log(facts)
		   return facts;
	   })
	   return  await Promise.all(newQList);
		  
			   });
    
}
const GenerateQuestionsFromSrc = async (topics,  src, num) => {
	let facts;
		if(!topics || topics === undefined || topics=== 'undefined'){topics = [];}

		let newQList;
	let limResult =	limiter.schedule(async() => {

 newQList =	topics.map(async(topic)=>{
		const response = await fetch(cfDir + `SFIQuery?topic=` + topic + "&src=" + src );
	const respTxt = await response.json();
 facts = respTxt //JSON.parse(respTxt.response.text);
	console.log(facts)
	return facts;
})
return  await Promise.all(newQList);
   
		});
		//await limResult;
//console.log("limResult")

}


const limiter = new Bottleneck({
	minTime: 3000,
	maxConcurrent: 1,
  });
const UpdateTopicIdx = async (topics,  qid) => {
	let r;
		
		const response =await fetch(cfDir + `updateTopicIdx?topic=` + topics + "&qid=" + qid );
	
		const respTxt = await response //.json();
 r = respTxt //JSON.parse(respTxt.response.text);
	console.log(r)


	return r;
    
}



const GetExplanation = async (topic,  model) => {
	let explanation;
		
		const response = await fetch(cfDir + `getConceptExplanation?topic=` + topic );
	const respTxt = await response.json();
	explanation = respTxt //JSON.parse(respTxt.response.text);
	console.log(explanation)


	return explanation;
    
}

const GetSFITopics = async (topic,  model) => {
	let topics;
		
		const response = await fetch(cfDir + 'getSFITopics', {
			method:"POST",
			headers: {
				"Content-Type": "application/json"
			},
		} );
	const respTxt = await response.json();
	topics = respTxt //JSON.parse(respTxt.response.text);
	let arrTopics =  Object.keys(topics.response).map(async(key)=>{
		//console.log(key)
		//console.log(topics.response[key])
		return Promise.resolve(topics.response[key])
	})
	
let allTopics =await Promise.all(arrTopics);
console.log("topics", allTopics)
	return allTopics;
    
}
const GetSFIActiveTopics = async (topic,  model) => {
	let topics;
		
		const response = await fetch(cfDir + 'getSFIActiveTopics', {
			method:"POST",
			headers: {
				"Content-Type": "application/json"
			},
		} );
	const respTxt = await response.json();
	topics = respTxt //JSON.parse(respTxt.response.text);
	let arrTopics =  Object.keys(topics.response).map(async(key)=>{
		//console.log(key)
		//console.log(topics.response[key])
		return Promise.resolve(topics.response[key])
	})
	
let allTopics =await Promise.all(arrTopics);
console.log("topics", allTopics)
	return allTopics;
    
}

const ParseSamples = async (content,  model) => {
	let q;
		
		const response = await fetch(cfDir + `parseNBMESamples?content=` + content );
	const respTxt = await response.json();
	q = respTxt //JSON.parse(respTxt.response.text);
	console.log(q)


	return q;
    
}

const UpdateQuestion = async (id, rowData) => {
	console.log(rowData)
	let strData= JSON.stringify(rowData)
	console.log(strData)
	const response = await fetch(cfDir + `updateQuestion?id=` + id + "&rowData=" + strData);
	const respTxt = await response.json();
let resp = respTxt //JSON.parse(respTxt.response.text);
	console.log(resp)

	return resp;
    
}

const DeleteQuestion = async (id) => {
		console.log(id)
	const response = await fetch(cfDir + `deleteQuestion?id=` + id );
	const respTxt = await response.json();
let resp = respTxt //JSON.parse(respTxt.response.text);
	console.log(resp)

	return resp;
    
}
const GetQuestions = async (id, num,cat,qTime) => {

	let questions;

		const response = await fetch(cfDir + `getQuestions?id=` + id + "&cat=" + cat + "&num=" + num);
		const respTxt = await response.json();
		 questions = respTxt.response 

		
		console.log(questions)
		localStorage.questions = JSON.stringify(questions)



	return questions;
    
}

const GetQuestionsById = async (ids) => {

	let questions;

		const response = await fetch(cfDir + `getQuestionsById?ids=` + ids);
		const respTxt = await response.json();
		questions = respTxt.response 
		console.log(questions)
		localStorage.questions = JSON.stringify(questions)



	return questions;
    
}
const BuildQuizQuestions = async (ids,quizSize, qTime) => {

	
		let questions = await GetQuestionsById(ids);
		if(questions.length >= quizSize){
		questions = questions.slice(0,quizSize);
		}else{
let newQuestions = await GetQuestions(undefined, quizSize - questions.length, undefined,undefined);
let arrNewQ= Object.keys(newQuestions).map((key)=>{
	let newQ = newQuestions[key];
	newQ.id = key;
	return newQ //newQuestions[key]
})
questions = [...questions, ...arrNewQ];

		}
			//questions = respTxt.response 
		console.log(questions)
		localStorage.questions = JSON.stringify(questions)



	return questions;
    
}


	
const GetMic= forwardRef((props,ref)=>{
	const [transcripts, setTranscripts] = useState([])
	const [micOn, setMicOn] = useState(false)
	useImperativeHandle(ref, () => ({

		pauseSpeech(){
			console.log("pausing speech")
			//pauseAudio();	
			console.log(sources[0])
		},
		resetMic() {
		  console.log("resetting mic")
		  SpeechRecognition.stopListening(); 
		  setTimeout(()=>{
			SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
		},550)
		}
	
	  }));

	const commands = [
{
	command:['one', 'two', 'three', 'four', 'five','the answer is *' ], 
callback: (command) => props.onFinalChange(command, 'answer')
},
	{command:['explain *' ], 
callback: (command) => props.onFinalChange(command, 'explain'),
// isFuzzyMatch: true,
//     fuzzyMatchingThreshold: 0.75,
//     bestMatchOnly: true
},
{command:['repeat the question','repeat the answers', 'yes' , 'no', 'next' , 'next question', 'pause', 'play'],
callback: (command) => props.onFinalChange(command, 'execute'),
	isFuzzyMatch: true,
    fuzzyMatchingThreshold: 0.75,
    bestMatchOnly: true
}
	]
	const {
		transcript,
		listening,
		resetTranscript,
		browserSupportsSpeechRecognition
	  } = useSpeechRecognition({commands});


	  const handleStart = async () => {
		console.log("start if not listening")
		console.log("listening",listening)
		if (!listening) {
		SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
		props.onStartListening();
		setTimeout(()=>{
		console.log("listening",listening)
		},2000)
		}
	  };


	  const handleStop =async()=>{
	 SpeechRecognition.stopListening();  
	 props.onStopListening();
	}

	if (!browserSupportsSpeechRecognition) {
		return <span>Browser doesn't support speech recognition.</span>;
	  }

	  return (
		<>
		<div className={listening ? "mic-wrapper listening" : "mic-wrapper"}>
		<Switch 
				checked={micOn} 
				label=""
				onChange={(e)=>{
					setMicOn(e.target.checked);
					console.log(e.target.checked)
					if(e.target.checked){
						
					handleStart();
					
					}else{
						
						handleStop();
					}
				
				}}
				></Switch> Hands Free Mode 
			
		</div>
	
		</>
	  )  
})
const GetExpMic= forwardRef((props,ref)=>{
	const [transcripts, setTranscripts] = useState([])
	const [micOn, setMicOn] = useState(false)
	useImperativeHandle(ref, () => ({

		pauseSpeech(){
			console.log("pausing speech")
			//pauseAudio();	
			console.log(sources[0])
		},
		resetMic() {
		  console.log("resetting mic")
		  SpeechRecognition.stopListening(); 
		  setTimeout(()=>{
			SpeechRecognition.startListening({ continuous: true, language: 'en-US' });
		},550)
		}
	
	  }));

	const {
		transcript,
		listening,
		resetTranscript,
		browserSupportsSpeechRecognition
	  } = useSpeechRecognition();


	  const handleStart = async () => {
		console.log("start if not listening")
		console.log("listening",listening)
		if (!listening) {
		SpeechRecognition.startListening({ continuous: false, language: 'en-US' });
		props.onStartListening();
		setTimeout(()=>{
		console.log("listening",listening)
		},2000)
		}
	  };


	  const handleStop =async()=>{
	 SpeechRecognition.stopListening();  
	 props.onStopListening(transcript);
	}

	useEffect(() => {
props.onFinalChange(transcript)
	}, [transcript]);

	if (!browserSupportsSpeechRecognition) {
		return <span>Browser doesn't support speech recognition.</span>;
	  }

	  return (
		<>
		
		<div className={listening ? "mic-wrapper listening" : "mic-wrapper"}>
			
		<div className="pulse-ring"></div>
		<div aria-label="record" onMouseDown={handleStart}  onMouseUp={handleStop} onTouchStart={handleStart}  onTouchEnd={handleStop} className="btn">
        <KeyboardVoiceIcon />
		
      </div>
		
		</div>	
	
	
		</>
	  )  
})

// const GetMic_Speechly = (props)=>{

// 	const { segment, listening, attachMicrophone, start, stop } = useSpeechContext();
// 	const [transcripts, setTranscripts] = useState([])
// 	const [micOn, setMicOn] = useState(false)
// 	const [ignoreMic, setIgnoreMic] = useState(true)
//   const [tentative, setTentative] = useState('');
  
//   const replies= ["one", "two", "three", "four", "five", "repeat the question","repeat the answers", "yes" , "no", "next question",]
 

// 	const handleStart = async () => {
// 		console.log("start if not listening")
// 		if (!listening) {
			
// 		  await attachMicrophone();
// 		  await start();
// 		}
// 	  };
	
// 	  const handleStop =async()=>{

// 		if (listening) {
// 			await stop();
// 			if(micOn){
// 			setMicOn(false)
// 			}
// 		  } 
// 	  }
// 	  useEffect(() => {
// 		if (segment) {
// 		  let resp = segment.words.filter((word) => {
// 			let newReply;
// 			if(replies.includes(word.value.toLowerCase())){
// 				newReply = word.value.toLowerCase();
// 				console.log("reply", word.value.toLowerCase())
// 				}
// 			return newReply  !== undefined;
// 		  })

		
// if(resp.length > 0){
// console.log("resp", resp);
// props.onFinalChange(resp)
// }
// 		}
			
// 			//).join(' ');
// 		//  setTentative(transcript);
// 	//	props.onTentativeChange(transcript);



// 		//   if (segment.isFinal) {
// 		// 	setTentative('');
// 		// 	console.log(segment)
// 		// 	console.log(transcript)

// 		// 	//let newTrans =  [...transcripts,transcript]
// 		// 	let newTrans =  transcript
// 		// 	console.log(newTrans)
// 		// 	setTranscripts(newTrans);
// 		// 	props.onFinalChange(transcript)
// 		// 	setMicOn(false);
// 		// 	//handleStop();
// 		// 	//console.log("transcripts", transcripts);
// 		//   }
// 		}
// 	  , [segment]);

// 	  return (
// 		<>
// 		<div className={listening ? "mic-wrapper listening" : "mic-wrapper"}>
// 		<Switch 
// 				checked={micOn} 
// 				label=""
// 				onChange={(e)=>{
// 					setMicOn(e.target.checked);
// 					console.log(e.target.checked)
// 					if(e.target.checked){
						
// 					handleStart();
					
// 					}else{
						
// 						handleStop();
// 					}
				
// 				}}
// 				></Switch> Hands Free Mode
// 		<div className="pulse-ringx"></div>
		
		
// 		</div>
		
		
// 		</>
// 	  )
// }


// const getTranscripts = (transcripts) => {
// if(transcripts.length > 0 ) {
// 	return transcripts.map((transcript) => {
// 		return <p>{transcript}</p>
// 	})
// }else{
// 	return <p>no transcripts yet</p>
// }
// }

const ExtractExamData = async() => {
let examData = await fetch("data/int_med_8.txt");
let examDataTxt = await examData.text();
return examDataTxt;

}



let sources = [];

const GetSpeech =  forwardRef((props,ref)=>{
let audioCtx ;
	let txt ;//= props.txt;
	let onFinished; // = props.onFinished;
	
	//const [sources, setSources] = useState([]);
	const  pauseAudio =()=>{
		let audioCtx ;
	
		// 
	//sources[0].pause();
	
	//	audioCtx = new window.AudioContext() 
	//	audioCtx.pause();
		//this.setState({sources:[]})
	
		console.log("pause audio", sources[0]);
	
		sources[0].playbackRate.value = 0.0;
	  }
		
	const  stopAudio =()=>{
		
	
		//let sources = this.state.sources;
		sources.map((src)=>{
			src.stop();
			return "stopped"
		})
	
	
		audioCtx = new window.AudioContext() 
		audioCtx.close();
		//this.setState({sources:[]})
		//setSources([]);
		//console.log("stop audio")
	  }

	  
	stopAudio();
	const playOutput = (buffer,onFinished)=>{

		// stop all audio sources
		sources.map((src)=>{
			src.stop();
			return "stopped"
		});
	//	setSources([]);
	sources = [];
		let audioData = new Uint8Array(buffer) ;
		// console.log(audioData)
	  
		let audioCtx ;
		// console.log("audiocontext", window.AudioContext.baseLatency)
	   //  audioCtx.close();
		 audioCtx = new window.AudioContext()
	
	  
		//   || window.webkitAudioContext)();
	let source = audioCtx.createBufferSource();
	//let arrSources = sources;
	//arrSources.push(source);
	//setSources(arrSources);
	sources.push(source);
	console.log("sources", sources)
	audioCtx.decodeAudioData(audioData.buffer.slice(0), (buffer)=> {
	  source.buffer = buffer;
	  source.connect(audioCtx.destination);
	  source.start(0);
	 // let sources = this.state.sources;
	 
	  //this.setState({isPlaying:true, sources:sources})
	  
	})
	
	source.onended=(e)=>{
		console.log(e)
	console.log(e.target.buffer.duration);
	
	console.log(e.target.context.currentTime)
	 console.log("sound ended");
	 if(e.target.context.currentTime >= e.target.buffer.duration){
		console.log(onFinished)
		if(onFinished){
	 onFinished()
		}
	 }
	//this.setState({isPlaying:false})
	}
	
	  }

	  const getAudioData = async (txt)=>{
		let cleanTxt = txt.replace(/\n/g, "");
		//cleanTxt = cleanTxt.replace(",", "");
		//https://us-central1-thackery-e30ca.cloudfunctions.net/getTTSVoice
		let audioDataResp = await fetch("https://us-central1-thackery-e30ca.cloudfunctions.net/getTTSSpeech?vModel=en-US-Wavenet-D&vType=MALE&ssml=" + encodeURIComponent(cleanTxt)) ;
		// console.log(audioDataResp)
		   let audioDataJSON =  await audioDataResp.json();
		   let audioData = audioDataJSON.response;
			//console.log(audioData)
		if(audioData.length > 0){
		 let resp = audioData[0] ;
		 // console.log(resp)
		  //console.log(resp.audioContent.data)
		  if(resp.audioContent.data.length > 0){
			 playOutput(resp.audioContent.data, onFinished );
		  }
		}
	  }
	  useImperativeHandle(ref, () => ({
speak(speech, onFinishedFn){
	onFinished = onFinishedFn;
	console.log("speaking", speech)
if(speech ){
	getAudioData(speech);
}
},
pauseSpeech(){
	console.log("pausing speech")
	//pauseAudio();	
	let src = sources[0];
	src.playbackRate.value = 0.0;
	console.log(sources[0])
},
playSpeech(){
	console.log("pausing speech")
	//pauseAudio();	
	let src = sources[0];
	src.playbackRate.value = 1.0;
	console.log(sources[0])
}

	  }))
return (<div></div>)

  })
 // const [sources,setSources] = useState(null);

 



export {  GetMic, GetExpMic, GenerateQuestions,GetExplainerResponse, GetRandomOrganPart, GetQuestionsById, UpdateTopicIdx,BuildQuizQuestions,GetSFITopics,GetSFIActiveTopics, GenerateQuestionsFromSrc, UpdateQuestion, GetQuestions, DeleteQuestion, GetExplanation, ExtractExamData, ParseSamples, GetSpeech,}