import logo from './images/thackery_icon_1.png';

import './App.css';

import * as React from 'react';

import Quiz from './js/quiz';
import {Portal} from './js/portal';
import DrUpload from './js/doctors';
import Editor from './Editor';
import UploadDoc from './js/embeddings';
import { RouterProvider,createBrowserRouter } from 'react-router-dom';

let qid = "";

const setupQid = (id)=>{
qid = "Nil4xGegBfe55a9UM1S" //id;
console.log("qid",qid)
return qid
}
const resetQid = (id)=>{
qid = ""
return qid;
}
const router = createBrowserRouter([
	{
		path:"/", element:<Portal /> ,
	},
	{
		path:"/professionals", element:<DrUpload /> ,
	},
	{
		path:"/quiz", element:<Quiz /> ,
	},
	{
		path:"/editor", element:<Editor />} ,
	{
		path:"/uploads", element:<UploadDoc />} ,
	
  ]);

function App() {
	return (
		<main>
			
			<RouterProvider router={router} />
		</main>
	);
}

export default App;
