
import React, { useEffect, useState, forwardRef, useRef } from 'react';

const UploadDoc = (props) => {

	const [file, setFile] = useState(null);
	const [src, setSrc] = useState('');
	const [type, setType] = useState('');
	const [uploading, setUploading] = useState(false);
	const [uploaded, setUploaded] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

const uploadEndPoint =  "https://us-central1-thackery-e30ca.cloudfunctions.net/uploadSFIDocs"


const startUpload = (e) => {
	const formData = new FormData();
e.preventDefault();
	
	formData.append('file', file);
	formData.append('src', src);
	formData.append('type', type);

	console.log("formdata", formData)
	fetch(uploadEndPoint, {
		method: 'POST',
		body: formData,
		encType: "multipart/form-data",
		// headers: {
		// 	"Content-Type": "multipart/form-data"
		//   }
		
	}).then((response) => {
		console.log(response)
	}).catch((error) => {
		console.log(error)
	})
}

return (
	<div>
		<h1>Upload PDF</h1>
		<p>Upload a PDF to be embedded in the page.</p>
		<form>
			<input type="text" name="src" value={src} onChange={(e)=> setSrc(e.target.value)} placeholder="Source" />
			<input type="text" name="type" placeholder="Type" value={type} onChange={(e)=> setType(e.target.value)} />
			<input type="file" name="file" accept=".pdf"  onChange={(e)=> setFile(e.target.files[0])} />
			<button type="submit" value="Upload" onClick={startUpload} >UPLOAD</button>
		</form>
	</div>
)

}

export default UploadDoc;