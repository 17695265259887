/* eslint-disable react-hooks/exhaustive-deps */
import logo from './images/thackery_icon_1.png';
import './App.css';

import * as React from 'react';
import Box from '@mui/material/Box';
//import Modal from '@mui/base/Modal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import Autocomplete from '@mui/material/Autocomplete';

import {GetMic,   GenerateQuestions, GetSFITopics, UpdateTopicIdx, GenerateQuestionsFromSrc, UpdateQuestion, GetQuestions, DeleteQuestion, ExtractExamData, ParseSamples} from './js/api';
import Quiz from './js/quiz';
import { useEffect, useState,  } from 'react';
import { useSearchParams,useLocation } from 'react-router-dom';
import { Icon } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//import {  Select, MenuItem } from "@material-ui/core";
import {  Select,  Modal, MenuItem, InputLabel, FormControl } from "@mui/material";
//import Grid from '@mui/material/Unstable_Grid2';
import {  GridRowsProp,
	GridRowModesModel,
	GridRowModes,
	DataGrid,
	useGridApiRef,
	useGridApiContext,
	GridColDef,
	GridToolbarContainer,
	GridActionsCellItem,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowEditStopReasons, } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fuse from 'fuse.js'
import Bottleneck from "bottleneck";

function Editor(props) {
  const [qInput, setQInput] = useState("");
  const [selectedSrc, setSelectedSrc] = useState("");
  const [archive, setArchive] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [popupText,setPopupText] = useState("");
  const [reset,setReset] = useState(false);
  const [openBkdrop, setOpenBkdrop] = useState(false);
  const [qExpanded, setQExpanded] = useState("");
  const [qEditMode, setQEditMode] = useState("");
  const [qEditData, setQEditData] = useState({id:null,accuracy:0});
  const [accuracy, setAccuracy] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [source, setSource] = useState('');
  const [cat, setCat] = useState('');
  const [created, setCreated] = useState('');
  const [updated, setUpdated] = useState('');
  const [status, setStatus] = useState('');
  const [uniqueness, setUniqueness] = useState('');
  const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState('');
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [explanation, setExplanation] = useState('');
  const [hint, setHint] = useState('');
  const [testQuestion, setTestQuestion] = useState('');
  const [filterKeys, setFilterKeys] = useState('');
  const [filtering, setFiltering] = useState(false);
  const [ignoreQid, setIgnoreQid] = useState(false);
  const [textSelection, setTextSelection] = useState(null);
  const [showQuiz,setShowQuiz] = useState(false)
  const [topicsList,setTopicsList] = useState([]);
const [selTopicsList,setSelTopicsList] = useState([]);
const [qId, setQId] = useState('');
  const [values, setValues] = React.useState([
    "Unsourced",
    "Pinecone DB",
    "Dataset 1",
    "Dataset 2",
  ]);
  const [srcSelected, setSrcSelected] = useState("");
  const [srcFilter, setSrcFilter] = useState("");
  const [fuseData, setFuseData]= useState([]);

 const [bkdropText, setBkdropText] = useState("...Evaluating Statement...");
 
  const archiveRef = React.useRef(null);
  const apiRef = useGridApiRef();
  const expRef = React.useRef(null);

const maxQuestions = 20;
 const [numQuestions, setNumQuestions] = useState(maxQuestions)

const [search] = useSearchParams();
//   const NewQid = ()=>{

// 	 let [srch] = useSearchParams();
// 	 console.log("new qid",[useSearchParams()][0])
// 	 console.log("new qid",srch)
// 	// if(qId === ""){
// 	//  //.get('qid');
// 	// setQId(srch.get('qid'));
// 	// }
// 	 return ''//srch.get('qid');
//   }



//  useEffect(() => {
// 	console.log("check for redirect")
// 	// function to get page url
// 	function getUrlVars() {
// 		var vars = {};
// 		var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
// 			vars[key] = value;
// 		});
// 		//console.log(parts)
// 		return parts;
// 	}

// 	let url = getUrlVars();
// 	console.log(url)
// 	if(url.includes('quiz')){
// 		setShowQuiz(true);
// 	}	
//  }, []);

useEffect(() => {
	getTopList();
	
},[]);

const qColumns=[
	//{field:'id', headerName:'ID', width:5},
	{ field: 'testQuestion', headerName: 'Questions', 
	 cellClassName: 'grid-q-cell',
	 editable:false,
renderCell: (params) => (
	<>
 	{qExpanded === params.row.id? true:false}
	<Accordion 
	 expanded={qExpanded === params.row.id? true:false}
	 onChange={()=>{
		handleQExpand(params.row.id, params.row);
	 }	}>
		<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"
		 id="panel1a-header"
		
		 >
				<div className={qEditMode === params.row.id ? "grid-q hidden" : "grid-q"}>

	<div className="grid-question">{params.row.testQuestion}</div>
	</div>
				<div className={qEditMode === params.row.id ? "grid-q edit-mode" : "grid-q edit-mode hidden"}>

	<div className="edit-question">
	<TextField 
	multiline={true}
	 variant="standard"
	 defaultValue={ testQuestion }
	 onChange={(e)=>{setTestQuestion(e.target.value)}}  
	></TextField>
		</div>
	</div>
	</AccordionSummary>
	<AccordionDetails>
	<div className={qEditMode === params.row.id ? "grid-details hidden" : "grid-details"}>
	<div className="grid-answers">{getAnswerSet(params.row.multipleChoiceAnswers, params.row.correctAnswer)}</div>
	
	<div className="grid-explanation">Explanation: {params.row.explanation}</div>
<div className="grid-topics">Hint: {params.row.hint}</div>
<div className="grid-topics">Topics: {params.row.categories}</div>
<div className="grid-eval">
	<div>Source: {params.row.src}</div>
	
	<div>Accuracy:{params.row.accuracy} </div>
	<div>Difficulty: {params.row.difficulty}</div>
	<div>Uniqueness: {params.row.uniqueness}</div>
	<div>Active: {params.row.status}</div>
	</div>
	<div className="edit-btns">

		<Button 
		variant="contained"
		onClick={()=>{startEditMode(params.row.id)}}
		>Edit Question</Button>
	</div>
	</div>	
	<div className={qEditMode === params.row.id ? "grid-details edit-mode" :"grid-details edit-mode hidden"}>
	<div className="edit-answers">
	{getEditAnswerSet(multipleChoiceAnswers, correctAnswer)}
	</div>
	
	<div className="edit-explanation">
	Explanation: <TextField 
	multiline={true}
	 variant="standard"
	//inputRef={expRef}
	defaultValue={ explanation }
	 onChange={(e)=>{
		 setExplanation(e.target.value);	
	}}  
	></TextField>
	</div>
<div className="edit-hint">Hint: <TextField 
  variant="standard"
  multiline={true}
  defaultValue={ hint }
onChange={(e)=>{setHint(e.target.value)}} 
></TextField> </div>
<div className="grid-topics">Topics: {params.row.categories}</div>
<div className="grid-eval">
	<div>Source: {params.row.src}</div>
	<div className="grid-topics accuracy">Accuracy: <TextField 
	 variant="standard"
	 multiline={true}
	 type="number"
	 defaultValue={ accuracy }
	 onChange={(e)=>{setAccuracy(parseInt(e.target.value))}}  
	></TextField></div>
	<div className="grid-topics difficulty">Difficulty: 
	<TextField 
	 variant="standard"
	 type="number"
	 value={ difficulty }
	 onChange={(e)=>{setDifficulty(parseInt(e.target.value))}}  
	></TextField>
	</div>
	<div className="grid-topics uniqueness">Uniqueness: {uniqueness}</div>
	<div className="grid-topics status">Active: 
	<TextField 
	 variant="standard"
	 defaultValue={ status }
	 multiline={true}
	 onChange={(e)=>{
		setStatus(e.target.value)}}  
	></TextField>
	</div>
	</div>
	<div className="edit-btns">

		<Button 
		variant="contained"
		onClick={(e)=>{saveQuestionChanges(e,params.row)}}
		>SAVE</Button>
		<Button 
		variant="contained"
		onClick={cancelQuestionChanges}
		>CANCEL</Button>
		<Button 
		variant="contained"
		onClick={(e)=>{deleteQ(params.row.id)}}
		>DELETE</Button>
	</div>
	</div>	
	</AccordionDetails>
	</Accordion>
	</>
),	
},
	// { field: 'multipleChoiceAnswers', headerName: 'Answers', width: 300, editable: true },
	// { field: 'correctAnswer', headerName: 'Correct Answer', width: 300 , editable: true},
	// { field: 'explanation', headerName: 'Explanation', width: 300, editable: true },
	// { field: 'source', headerName: 'Source', width: 300, editable: true },
	// { field: 'topic', headerName: 'Topic', width: 300, editable: true },
	// { field: 'difficulty', headerName: 'Difficulty', width: 300, editable: true },
	// { field: 'tags', headerName: 'Tags', width: 300, editable: true },
	// { field: 'type', headerName: 'Type', width: 300, editable: true },
	// { field: 'id', headerName: 'ID', width: 300 , editable: true},
	// { field: 'created', headerName: 'Created', width: 300, editable: true },
	// { field: 'updated', headerName: 'Updated', width: 300, editable: true },
	// { field: 'status', headerName: 'Status', width: 300, editable: true },

]

const handleQExpand = (id,rowData)=>{
	if(qEditMode === ""){
	if(qExpanded === id){
		setQExpanded("");
		//setActiveQData({});
	}else{
	populateParams(rowData)
	setQExpanded(id);
	setActiveQData(id,rowData);
	}
}
}

const handleRowUpdate = (e, id, field)=>{
	console.log(e.target.value)
	console.log(id)
	console.log(field)
let newData = {...qEditData};
newData[field] = e.target.value;
	setQEditData( newData);
	console.log(newData)
	console.log(qEditData)
	
}

const startEditMode = (id)=>{
console.log(id)
setQEditMode(id);
//setActiveQData(id);
console.log("editdata",qEditData)
}

const setActiveQData=  (id, rowData)=>{
console.log(rowData)

setQEditData(rowData);

}

// const handleEdit=(e,fn,val)=>{
// //let setFn = "set" + field;
// let newFn = eval(fn)
// newFn(val)
// setTextSelection([e.target.selectionStart, e.target.selectionEnd]);
        
// }

const testRef = ()=>{
	console.log("set selection", textSelection)
	console.log("set selection", expRef.current)
   expRef.current.selectionStart = 0;
	expRef.current.selectionEnd = 25;
//expRef.setSelectionRange(textSelection[0], textSelection[1]);
	console.log("set selection", expRef.current.selectionStart)
	
	// let range = document.createRange();
	// range.setStart(expRef.current, textSelection[0]);
	// range.setEnd(expRef.current, textSelection[1]);
	// console.log("set selection", range)
	// let sel = window.getSelection();
	// sel.removeAllRanges();
	// sel.addRange(range);
	// console.log("set selection", sel)


	expRef.current.style.color = "red";
}
React.useEffect(() => {
    if (textSelection && expRef.current) {
		
		console.log("set selection", textSelection)
		console.log("set selection", expRef.current)
       expRef.current.selectionStart = textSelection[0];
		expRef.current.selectionEnd = textSelection[1];
	//expRef.setSelectionRange(textSelection[0], textSelection[1]);
		console.log("set selection", expRef.current.selectionStart)
		
		// let range = document.createRange();
		// range.setStart(expRef.current, textSelection[0]);
		// range.setEnd(expRef.current, textSelection[1]);
		// console.log("set selection", range)
		// let sel = window.getSelection();
		// sel.removeAllRanges();
		// sel.addRange(range);
		// console.log("set selection", sel)


		expRef.current.style.color = "red";
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explanation]);

  React.useEffect(() => {
//console.log("questions updated")
  }, [questions]);

const getDataParams= ()=>{
let obj ={};
obj.testQuestion = testQuestion;
obj.correctAnswer = correctAnswer;
obj.multipleChoiceAnswers = multipleChoiceAnswers;
obj.explanation = explanation;
obj.hint = hint;
obj.src = selectedSrc;
obj.updated = new Date().toISOString;
obj.categories = cat;
obj.accuracy = accuracy;
obj.difficulty = difficulty;
obj.uniqueness = uniqueness;
obj.status = status;
return obj;
}

const saveQuestionChanges = (e,row)=>{
	//row = {...qEditData};
console.log("q",e)
console.log("save q",row)

UpdateQuestion(row.id, {...row, ...getDataParams()});
//let newData = {...qEditData};
//console.log("new data",newData)
apiRef.current.updateRows([{...row, ...getDataParams()}]);
setQEditMode("");
return null;
}

const deleteQ = async(id)=>{
let res = await DeleteQuestion(id);
if(res.response === "deleted"){
	//apiRef.current.deleteRows([id]);
	removeRow(id);
	setQEditMode("");
	return null;
}
}

const removeRow = (id)=>{
	console.log(id)
	let rows = [...questions];
	let newRows = rows.filter((r)=>{
		return r.id !== id;
	})
	console.log(newRows)
	setQuestions(newRows);
}

const populateParams = (q)=>{
	if(q){
console.log("q",q)
	setTestQuestion(q.testQuestion);
	setCorrectAnswer(q.correctAnswer);
	setMultipleChoiceAnswers(q.multipleChoiceAnswers);
	setExplanation(q.explanation);
	setHint(q.hint);
	//setSource(q.source);
	setSelectedSrc(q.src);
	if(q.accuracy){
	setAccuracy(parseInt(q.accuracy));
	}else{
		setAccuracy(0)
	}

	if(q.difficulty){
	setDifficulty(q.difficulty);
	}else{
		setDifficulty(0)
	}

	if(q.uniqueness){
	setUniqueness(q.uniqueness);
	}else{
		setUniqueness(0)
	}

	if(q.status){
	setStatus(q.status);
	}else{
		setStatus("")
	}

	if(q.created){
	setCreated(q.created);
	}else{
		setCreated(null)
	}

	if(q.updated){
	setUpdated(q.updated);
	}else{
		setUpdated(null)
	}
	setCat(q.cat);
}
}
const cancelQuestionChanges = (id)=>{
console.log(id)
setQEditMode("");
return null;
}


const getAnswerSet = (arrAnswers, corr) => {
	let jsxAnswers = arrAnswers.map((a, i)=>{
		if(a === corr){

			return (<div className="grid-answer correct" key={i}>{a}</div>)
		}else{
		return (<div className="grid-answer" key={i}>{a}</div>)
		}
	})
	return jsxAnswers;
	
}


const updateAnswerSet = (val,idx, updateCorrect)=>{
let newAnswers = [...multipleChoiceAnswers];
newAnswers[idx] = val;
//setMultipleChoiceAnswers(newAnswers)
console.log("new answers",newAnswers)
if(updateCorrect === true){
	setCorrectAnswer(val)
}
return newAnswers
}

const updateCorrectAnswer = (idx)=>{
let val = multipleChoiceAnswers[idx]
	setCorrectAnswer(val)
}

const getEditAnswerSet = (arrAnswers, corr) => {
	//console.log(arrAnswers)
	let jsxAnswers = <div>-</div>
	if(arrAnswers && arrAnswers.length > 0){
	 jsxAnswers = arrAnswers.map((a, i)=>{
		if(a === corr){

			return (<div className="edit-answer correct" key={i}>
			 <TextField 
	 variant="standard"
	 defaultValue={ a }
	 onChange={(e)=>{setMultipleChoiceAnswers(updateAnswerSet(e.target.value,i,true))}} 
	></TextField>

	<Radio checked={true}></Radio>
			</div>)
		}else{
		return (<div className="edit-answer" key={i}>
		 <TextField 
	 variant="standard"
	 defaultValue={ a }
	 onChange={(e)=>{setMultipleChoiceAnswers(updateAnswerSet(e.target.value,i,false))}}  
	></TextField>
	<Radio checked={false} onClick={(e)=>{updateCorrectAnswer(i)}}></Radio>
		</div>)
		}
	})
}
	return jsxAnswers;
	
}
 const openPopup= (text)=>{
	setPopupText(text);
	setOpenModal(true);
 }

  const handleCloseModal = ()=>{
	setOpenModal(false);
	  }

 
  
  const updateInput = (newItem) => {

setQInput(newItem);

  }

  const getSpeech = async (txt)=>{

	
	let cleanTxt = txt.replace(/\n/g, "");
	cleanTxt = cleanTxt.replace(",", "");
	//https://us-central1-thackery-e30ca.cloudfunctions.net/getTTSVoice
	let audioDataResp = await fetch("https://us-central1-thackery-e30ca.cloudfunctions.net/getTTSSpeech?vModel=en-US-Wavenet-D&vType=MALE&ttstext=" + encodeURIComponent(cleanTxt)) ;
	// console.log(audioDataResp)
	   let audioDataJSON =  await audioDataResp.json();
	   let audioData = audioDataJSON.response;
		console.log(audioData)
	if(audioData.length > 0){
	 let resp = audioData[0] ;
	  console.log(resp)
	  //console.log(resp.audioContent.data)
	  if(resp.audioContent.data.length > 0){
	  playOutput(resp.audioContent.data )
	  }
	}
  
  }

  const playOutput = (buffer)=>{
	let audioData = new Uint8Array(buffer) ;
	// console.log(audioData)
  
	let audioCtx ;
	// console.log("audiocontext", window.AudioContext.baseLatency)
   
	 audioCtx = new window.AudioContext()
  
	//   || window.webkitAudioContext)();
let source = audioCtx.createBufferSource();
audioCtx.decodeAudioData(audioData.buffer.slice(0), (buffer)=> {
  source.buffer = buffer;
  source.connect(audioCtx.destination);
  source.start(0);
 // let sources = this.state.sources;
  //sources.push(source)
  //this.setState({isPlaying:true, sources:sources})
  
})

source.onended=()=>{

// console.log("sound ended")
//this.setState({isPlaying:false})
}

  }





const clearData = ()=>{
	localStorage.removeItem("questions");
	setQuestions([]);
}

const generateQuestions = async() => {
	let qSet;
// 	let qStore = localStorage.getItem("questions")
// 	if(qStore && qStore !== '' && reset === false){
// let qSetSaved = JSON.parse(localStorage.getItem("questions"));
// qSet = qSetSaved.map((q,idx)=>{
	
// 	if(!q.topics){q.topics = ""}
// 	if(!q.hint){q.hint = ""}
// 	if(!q.explanation){q.explanation = ""}
// 	if(!q.source){q.source = ""}
// 	if(!q.topic){q.topic = ""}
// 	if(!q.difficulty){q.difficulty = ""}
// 	if(!q.tags){q.tags = ""}
// 	if(!q.type){q.type = ""}
// 	if(!q.created){q.created = ""}
// 	if(!q.updated){q.updated = ""}
// 	if(!q.status){q.status = ""}
// 	if(!q.accuracy){q.accuracy = 0}
// 	if(!q.multipleChoiceAnswers){q.multipleChoiceAnswers = []}
// 	if(!q.correctAnswer){q.correctAnswer = ""}
// 	if(!q.testQuestion){q.testQuestion = ""}


// 	return {id:q.id,testQuestion:q.testQuestion,hint:q.hint, topics: q.categories,multipleChoiceAnswers:q.multipleChoiceAnswers, correctAnswer:q.correctAnswer, explanation:q.explanation, source:q.source, topic:q.topic, difficulty:q.difficulty, tags:q.tags, type:q.type,  created:q.created, updated:q.updated, status:q.status, accuracy:q.accuracy}
// })
// setQuestions(qSet);
// 	}else{
	setBkdropText("...Generating Questions...");
	setOpenBkdrop(true);

// let resp = await GenerateQuestions(topics, 5, "gpt-3.5-turbo");
// qSet = resp.response;
// console.log("new questions",qSet)

//let facts = await GenerateQuestionsFromSrc(topics, "usmle_flash_2") //secret-usml
//console.log("facts",facts)
if(selectedSrc !=="" && selectedSrc !== "Unsourced" && topics ){

let newq = await GenerateQuestionsFromSrc(selTopicsList, selectedSrc, numQuestions);
console.log("newq",newq);
}else{
	let resp = await GenerateQuestions(selTopicsList, 5, "gpt-3.5-turbo");
	//qSet = resp.response;
	console.log("new questions",resp)

}
//let tidx= await UpdateTopicIdx("Obstetrics, Immunology,Bone Disease", "-NdJysVkkeA2kGb-BbS1");
//console.log("tidx",tidx)
getQuestions(500);
//setQuestions(qSet);
setOpenBkdrop(false);
}

const getQuestions = async(num,cat,id) => {
// let examData = await ExtractExamData();
// //console.log("exam",examData)
// let arrExam = examData.split("Exam Section :")
// //console.log("exam",arrExam[58])

// //console.log("arrqexam",arrQ)
// let arrObjExam= arrExam.map(async (e)=>{
// 	let obj = await ParseSamples(e, "gpt-3.5-turbo");
// 	console.log("obj",obj)
//  })

//  console.log("objexam",arrObjExam)

let qSet
	 qSet = await GetQuestions(id, num, cat);

// qSet = qSet.response;

console.log(qSet)
	let arrQSet = Object.keys(qSet).map((q,idx)=>{
	//	console.log(qSet[q])
	
		if(!qSet[q].src){
			qSet[q].src = ""
		}
		
		if(qSet[q].id){
return qSet[q];
	}else{
		qSet[q].id = q;
		return qSet[q];
	}
	})

	// reverse an array order
	arrQSet = arrQSet.reverse();
	//console.log(arrQSet[0])
	setQuestions(arrQSet);
	//setQuestions([]);
return "ready";
}


const limiter = new Bottleneck({
	minTime: 5000,
	maxConcurrent: 1,
  });

 
React.useEffect(() => {
let newqid = search.get('qid');
console.log("search updated", newqid)
if(newqid !== qId){
	console.log("new qid",newqid)
	setQId(newqid);
}
}, [search]);

React.useEffect(() => {
	console.log("questions updated", qId)

	if(qId !== "" && ignoreQid === false){
setFilterKeys(qId);
setFiltering(true)

 }
	if(filterKeys !== "" && questions.length > 0 && filtering === true){
		setFiltering(false);
		setFilter(filterKeys)

	}

	console.log("questionsloaded", questions)
	 if(questions.length > 0){

	//	if(props.qid !== ""){
		//	console.log("qid",props.qid)
	//startEditMode(props.qid);
	//setFilter("startedit");
	//handleQExpand(props.qid, questions[0]);
	//setQExpanded(props.qid);
			//}
	// questions.map(limiter.wrap(async(q,idx)=>{
	// 	if(idx >= 0){
	// 	let tidx= 	await UpdateTopicIdx(q.categories, q.id);
	// 	}
	// }))
	}
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [questions]);

let handleSrcChange = (e) => {

	setSrcSelected(e.target.value);
}


let handleUserInput = async(e) => {

// 	setBkdropText("...Asking Your Question...");
// 	setOpenBkdrop(true);
// 	updateArchive(qInput);
// 	updateQuestions(qInput);
// 	let resp = await HandleJuicenutQuery(qInput)
// 	console.log("parsed response:", resp)
// 	//setBkdropText("...Getting Answer...");
	
// 	updateArchive(qInput + "\n" + resp.text)
// 	//let arrUrls = getUrlStr(resp.urls);
// updateAnswers(resp.text , resp.urls);
// getSpeech(resp.text);
// 	setQInput("");
	
// 	setOpenBkdrop(false);

	
}

const showQuizDemo = ()=>{
	setShowQuiz(true);
}

useEffect(() => {
resetFilter()
},[srcFilter,filterKeys]);

const resetFilter = ()=>{
	setFilter(filterKeys,srcFilter)
}
const setFilter = (val,src)=>{
	let logSearch ;
	console.log("srcfilter", srcFilter)
	setOpenBkdrop(true);
	setBkdropText("...Filtering Questions...");
console.log("filtervals", val, srcFilter)
	if(val !== "" || (srcFilter !== undefined && srcFilter !== "")){
		
		let arrSearch = [];
		if(val !== ""){
	setFilterKeys(val);		
let multi = val.split(",");

multi.forEach((m)=>{
	let obj1= {testQuestion: m.trim()}
	arrSearch.push(obj1);
	let obj2= {categories: m.trim()}
	arrSearch.push(obj2);
	let obj3= {explanation: m.trim()}
	arrSearch.push(obj3);
	let obj4= {correctAnswer: m.trim()}
	arrSearch.push(obj4);
	if(props.qid !== ""){
	let obj5= {id: m.trim()}
	arrSearch.push(obj5);
	}
	//let obj5= {source: m.trim()}
	
		
	
	let obj6= {multipleChoiceAnswers: m.trim()}
	arrSearch.push(obj6);
	let obj7= {status: m.trim()}
	arrSearch.push(obj7);
})
		}

let fSrc = "";
if(srcFilter !== undefined && srcFilter !== "" ){
console.log("src",srcFilter)
	 fSrc=[ {src: srcFilter}, ]; //{src: '!""'}
}


if(arrSearch.length > 1 || fSrc !== ""){
	let srch1 ="";
	if(arrSearch.length > 0){
		logSearch = {$or: arrSearch};
		srch1 = {$or: arrSearch};
		if(srcFilter !== ""){
		//logSearch = {$and: [srch1,{src: srcFilter}]};
		}else{
			logSearch = srch1;
		}
	}else{

		logSearch = {$and: [...fSrc]};
	}

}
//logSearch = arrSearch

//	logSearch = {$or: arrSearch};

console.log("srch",logSearch);


		let fuse = new Fuse(questions, {
			keys: [
			  'testQuestion',
			  'categories',
			  'correctAnswer',
			  'explanation',
			  'hint',
			  'src',
			  'status',
			  'id',
			],
			findAllMatches:true,
			minMatchCharLength:1,
			threshold: 0.4,
			ignoreLocation:true,
		  });
		  let result = fuse.search(logSearch);
		 
		  console.log("filtered",result)
		  let arrQSet = result.map((q,idx)=>{
			//	console.log(qSet[q])
				if(q.item.id){
		return q.item;
			}else{
				q.item.id = q;
				return q.item;
			}
			})
			setQuestions(arrQSet);
			setOpenBkdrop(false);
		

	}else{
		getQuestions(500);
		setOpenBkdrop(false);
	}
}

const getAnswer = (resp) => {
if(resp){
	let respUrls = resp.urls;
	let arrUrls = [];
	if(resp.text.indexOf("information") >-1 && resp.text.indexOf("context") >-1){
		if(respUrls.length > 0){
		resp.text = "I'm sorry, I'm having trouble summarizing my answer. The link below may answer your question."
		}else{
			resp.text = "I'm sorry, I'm having trouble answering your question. Try rephrasing it and I'll try again."
		}
		}
		
	
	return <div>
		<div>{resp.text}</div>
		{respUrls.map((u)=>{
			let title = u.url.substring(u.url.lastIndexOf('/') + 1, u.url.length - 19 );
			title = title.replace(/_/g, " ");
			//title = title.
			let link = <div><a href={u.url} target="_blank" rel="noreferrer" >{title}</a></div>
			//strUrls += link ;
			arrUrls.push(link);
			return link;
		})}
		</div>;
}else{
	return "";
}
}


const getTopList =async ()=>{

	let topics = await GetSFITopics();
	
	// sort array
	topics = topics.sort((a,b)=>{
		if(a.text < b.text){
			return -1;
		}
		if(a.text > b.text){
			return 1;
		}
		return 0;
	})
	//console.log("sorted topics",topics)
	setTopicsList(topics);
}


const addToSelTopics = (e, value)=>{
	// let sel = [...selTopicsList];
	// if(value){
	// sel.push(value);
	setSelTopicsList(value);
	
	}
// useEffect(() => {
	
// 		if(archiveRef.current){
// 		console.log("scroll to bottom");
// 		console.log(archiveRef.current.scrollHeight)
// 		console.log(archiveRef.current.scrollTop)
// 		 archiveRef.current.scrollTop = archiveRef.current.scrollHeight;
// 		}
// }, [answers]);
  
  return (
    <div className="App">

<div className={showQuiz === false ? "quiz-wrapper hidden" : "quiz-wrapper"}>
	{showQuiz === true ?
	<div>
		<Quiz hideQuiz={()=>setShowQuiz(false)} />
	</div>
	:""}
	  </div>
      <header className="App-header">
	  <div className='logo-wrapper'>
        <img src={logo} className="logo" alt="logo" />
	</div>
		<div className='titles'>
		<div className="heading">NBME Question Generator</div>
		
		</div>
		<div className='nav hidden'>
			
			<div className="menu-item" onClick={()=>openPopup()} >Instructions</div>
			<div className="menu-divider">|</div>
			<div className="menu-item"  onClick={()=>openPopup()} >Contact</div>
		</div>
		<div className="btn-quiz-wrapper">
		<Button 
className="btn-quiz hidden"
variant="contained"
onMouseDown={async() => {
showQuizDemo();	
}}>Quiz Demo</Button>	
</div>
      </header>

	  <div className="dialog-wrapper">
		<div className="main-frame">
			<div className="dialog-frame">
				<div className="param-panel">
					<FormControl>
				<InputLabel id="label-src">Source</InputLabel>
	
	<Select
         // labelId="label-src"
          id="ddl-src"
          value={selectedSrc}
          labelId="label-src"
		className="src-select"
		label="Source"
		  placeholder='Select a source...'
          onChange={(e)=>{setSelectedSrc(e.target.value)}}
        >
			<MenuItem value=''>Select a Source</MenuItem>
			<MenuItem value='usmle_flash_2'>USMLE Flash 2</MenuItem>
			<MenuItem value='int-med-8'>Internal Medicine</MenuItem>
			</Select>
			</FormControl>
{topicsList.length > 0 ?
			<Autocomplete
//ref={topicsRef}
  disablePortal
  id="autoTopics"
  multiple 
  className="auto-topics-editor"
 onChange={addToSelTopics}
 options={topicsList.sort((a, b) => -b.trim().localeCompare(a.trim()))}
 getOptionLabel={(option) => option.trim()}
 // options={topicsList}
  sx={{ width: '100%' }}
  renderInput={(params) => <TextField {...params} label="Topics" />}
/>   
:""}
				
	
<Button 
className="hidden"
variant="contained"

onMouseDown={async() => {
clearData();	
}}>Clear Question List</Button>	
<Button 
variant="contained"
onMouseDown={async() => {
	 generateQuestions();
}}>Create Questions</Button>	

<TextField
		name ="filter-topics"
          id="filterTopics"
		  variant="outlined"
		  className="q-filter-input"
		  value={filterKeys}
            onChange={(e) => setFilterKeys(e.target.value)}
			label='Filter Questions'
          placeholder='Add a topic or phrase to filter questions...'
         
        />
			<FormControl>
				<InputLabel id="label-src">Source</InputLabel>
	
	<Select
         // labelId="label-src"
          id="ddl-src"
          value={srcFilter}
          labelId="label-src"
		className="src-select"
		label="Source"
		  placeholder='Select a source...'
          onChange={(e)=>{
			setSrcFilter(e.target.value)
		
		}}
        >
			<MenuItem value=''>Select a Source</MenuItem>
			<MenuItem value='usmle_flash_2'>USMLE Flash 2</MenuItem>
			<MenuItem value='int-med-8'>Internal Medicine</MenuItem>
			</Select>
			</FormControl>
<Button 
variant="contained"
onMouseDown={async() => {
	if(filterKeys !== ""){
	let ready =	await  getQuestions(500);
	console.log("ready",ready)
	setFiltering(true);
//setFilter(filterKeys)
	}else{
	 getQuestions(500);
	}
}}>Find Questions</Button>	
<Button 
variant="contained"
onMouseDown={async() => {
setFilterKeys("");
setSrcFilter("");
setIgnoreQid(true);
getQuestions(500);
}}>Clear</Button>	
				</div>
				
				<div className="q-set">


<div className="grid-wrapper">
	
<DataGrid
apiRef={apiRef}
getRowHeight={() => 'auto'}
        rows={questions}
        columns={qColumns}
		//autoPageSize {...questions}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8,
            },
          },
        }}
        pageSizeOptions={[5, 8, 15, 20, 30, 50]}
		
		showFirstButton
		showLastButton
        disableRowSelectionOnClick
      />
</div>

</div>
</div>
<div className={"patient-statements hidden"  }>
	
				
				<div className={"talk-frame" }>
				<div className='mic'>
	<GetMic onTentativeChange={updateInput} onFinalChange={updateInput} val={null} />
</div>
		<TextField
		name ="q"
          id="q"
		  variant="outlined"
		  className="q-input"
		  value={qInput}
            onChange={(e) => setQInput(e.target.value)}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					handleUserInput();
				}
			}}
          label='Ask a question...'
          placeholder='Ask a question...'
          multiline
        />
		
		<div className="submit-btn">

<Button 

variant="contained"

onMouseDown={async() => {
	setQInput("");
}}>Clear</Button>	
<Button 

variant="contained"

onMouseDown={async() => {
	handleUserInput();
	
}}>Submit</Button>	


				</div>



			
				
				</div>
				</div>

				<div className="talkframe-wrapper">

				
		
				
		</div>
		</div>
		
	  </div>
	
	  <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={openBkdrop}
  
>
<CircularProgress color="inherit" />
<div className='bkdrop-text'>{bkdropText}</div>
</Backdrop>
<Modal 
	 open={openModal}
	 >
		<Box className="popup-box">
		<Icon className="close-btn" onClick={handleCloseModal}><HighlightOffIcon className="icon" /></Icon>
	<div>{popupText}</div>
	</Box>
		</Modal>
	</div>
	
  );
}

export default Editor;
